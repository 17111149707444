export const getImprovements = `query improvements($query: ImprovementQueryInput, $limit: Int, $sortBy: ImprovementSortByInput){
  improvements(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    note
    description
    dueDate
    assignee {
      _id
      name
      email
      locations {
        _id
        location
      }
    }
    tags
    deleted
    origin
    origin_id
    completed
    important
    imageKey
    evidenceKey {
      key
      type
    }
    metadata {
      name
      type
      answer
    }
    subscribers {
      _id
    }
    assistance
  }
}`

export const queryImprovement = `query improvement($query: ImprovementQueryInput){
  improvement(query: $query) {
    _id
    note
    description
    dueDate
    assignee {
      _id
      name
      email
      locations {
        _id
        location
      }
    }
    tags
    deleted
    origin
    origin_id
    completed
    important
    imageKey
    evidenceKey {
      key
      type
    }
    metadata {
      name
      type
      answer
    }
    subscribers {
      _id
    }
    assistance
  }
}`

export const addImprovement = `mutation insertOneImprovement($data: ImprovementInsertInput!){
  insertOneImprovement(data: $data) {
    _id
    note
    description
    dueDate
    assignee {
      _id
      name
      email
    }
    tags
    deleted
    completed
    metadata {
      name
      type
      answer
    }
    subscribers {
      _id
      name
      email
    }
  }
}`

export const updateImprovement = `mutation updateOneImprovement($query: ImprovementQueryInput!, $data: ImprovementUpdateInput!) {
  updateOneImprovement(query: $query, set: $data) {
    _id
  }
}`