import axios from '@axios'
import { queryEvents, updateEvent, addEvent, removeEvent } from '@/@core/queries/calendar'
import { addImprovement, updateImprovement } from '@/@core/queries/improvements'
import store from '@/store'
import { get } from 'idb-keyval';

const updateLocationConditions = (items, category, query) => {
  const filterQuery = items.map(e => ({locations_in: {[category]: e}}))
  query.OR[0].attendee.AND.push({OR: filterQuery})
  query.OR[1].attendee.AND.push({OR: filterQuery})
  query.OR[2].AND.push({organizer: {OR: filterQuery}})
  if (query.OR[3]) query.OR[3].attendee.AND.push({OR: filterQuery})
}

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'warning',
        label: 'Pendientes',
      },
      {
        color: 'danger',
        label: 'Vencidas',
      },
      {
        color: 'success',
        label: 'Realizadas',
      },
      {
        color: 'info',
        label: 'Futuras',
      },
      {
        color: 'secondary',
        label: 'En espera',
      },
    ],
    selectedCalendars: ['Pendientes', 'Vencidas', 'En espera'],
    instancesFromEmail: 0
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    INCREMENT_INSTANCES(state) {
      state.instancesFromEmail++
    },
  },
  actions: {
    fetchEvents(ctx, { calendars, locations, roles, workers, month, year, zones, agencies, commitmentFunctionality }) {
      // TODO: This filter should be at the backend
      const getUserData = store.state?.userStore?.userData
      const userId = getUserData.worker_id != null ? getUserData.worker_id.$oid : null
      const userLocationsQuery = getUserData.locations?.map(e => ({ _id: e.value }))

      const firstDayOfCurrentMonth = new Date(year, month, 1)
      const firstDayOfNextMonth = new Date(year, month + 1, 1)

      const query = {
        client_id: (getUserData.role != 'admin') ? { _id: getUserData?.client?.$oid} : null,
        AND: [{ OR: [{ extendedProps: { calendar_in: calendars } }] }],
        //Set query to fetch only the events in the selected month and year
        start_gte: firstDayOfCurrentMonth,
        start_lt: firstDayOfNextMonth,
        deleted_ne: true,
      }

      if (calendars.includes('En espera')) {
        query.AND[0].OR[1] = { confirmation: { pending: true } }
      } else {
        query.AND[1] = { OR: [ { confirmation_exists: false }, { confirmation: { pending_ne: true } }] }
      }

      if (commitmentFunctionality) {
        query.AND.push({ OR: [ { isInstance: true }, { isBehaviourEvaluation: true }] })
        if (getUserData.role === 'supervisor') {
          query.OR = [
            { organizer: { _id: userId } },
            { organizer: { locations_in: userLocationsQuery } },
            { participants_in: { _id: userId } }
          ]
        }
      } else {
        query.OR = [
          { attendee: { AND: [{ OR: [{ deleted: false }, { deleted_exists: false }] }] } },
          { 
            attendee: { AND: [{ deleted: true }] },
            extendedProps: { calendar: "Realizadas"}
          },
          { AND: [{ attendee_exists: false }]}
        ]

        if (getUserData.role === 'supervisor') {
          query.OR[0].attendee.AND.push({supervisors_in: {_id: userId}})
          query.OR[1].attendee.AND.push({supervisors_in: {_id: userId}})
          query.OR[2].AND.push({organizer: {locations_in: userLocationsQuery}})
          query.OR[3] = {attendee: {AND: [{_id: userId}]}}
        }
      }

      if (locations.length > 0) updateLocationConditions(locations, "_id", query)
      if (zones.length > 0) updateLocationConditions(zones, "zone", query)
      if (agencies.length > 0) updateLocationConditions(agencies, "agency", query)

      if (roles.length > 0) {
        query.attendee = {roles_in: roles}
      }

      if (workers.length > 0) {
        if (query.attendee) query.attendee._id_in = workers
        else query.attendee = {_id_in: workers}
      }

      return new Promise((resolve, reject) => {
        const isOnline = store.state.app.isOnline
        if (isOnline) {
          // eslint-disable-next-line import/no-named-as-default-member
          axios
            .post('/graphql', {
              query: queryEvents,
              variables: {
                query,
                limit: 10000,
              },
            })
            .then(response => {
              if (response.data.errors) throw new Error(response.data.errors[0].message)
              const events = response.data?.data?.events
              resolve(events)
            })
            .catch(error => {
              reject(error)
            })
        }
        else {
          get('events').then(response => {
            if (response) resolve(response)
            else if (localStorage.events) resolve(JSON.parse(localStorage.events))
            else reject({ isStorageError: true })
          })
        }
      })
    },

    async addEvent(ctx, { event, createImprovements }) {
      const getUserData = store.state?.userStore?.userData
      const userId = getUserData.worker_id != null ? getUserData.worker_id.$oid : null

      const newEvent = {
        client_id: getUserData?.client?.$oid ? { link: getUserData.client.$oid } : null,
        isInstance: true,
        extendedProps: { calendar: 'Pendientes' },
        start: event.start instanceof Date ? event.start : new Date(event.start),
        end: event.end instanceof Date ? event.end : new Date(event.end),
        title: event.title,
        organizer: { link: userId }
      }

      if (newEvent.end <= newEvent.start) {
        newEvent.end = new Date(newEvent.start)
        newEvent.end.setHours(newEvent.end.getHours() + 1)
      }

      if (event.extendedProps.metadata?.length) {
        newEvent.metadata = event.extendedProps.metadata.map(m => ({
          name: m.name,
          type: m.type,
          answer: m.answer,
        }))
      }

      if (event.extendedProps.participants?.length) {
        newEvent.participants = { link: event.extendedProps.participants }
      }

      if (event.extendedProps.improvements?.length && createImprovements) {
        try {
          const newImprovementIds = await createImprovements(event.extendedProps.improvements, "event", null)
          if (newImprovementIds?.length) {
            newEvent.improvements = { link: newImprovementIds }
          }
        } catch (error) {
          console.log(error)
        }
      }

      const query = {
        query: addEvent,
        variables: { data: newEvent },
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/graphql', query)
          .then(({data}) => {
            if (data.errors) throw new Error(data.errors[0].message)
            resolve(data.data.insertOneEvent._id)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async updateEvent(ctx, { event, createImprovements, commitmentFunctionality }) {
      const now = new Date()
      const firstDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)

      const updatedEvent = {
        attendee: event.extendedProps.attendee ? { link: event.extendedProps.attendee._id } : null,
        process: event.extendedProps.process ? { link: event.extendedProps.process._id } : null
      }

      if (event.extendedProps.metadata) {
        updatedEvent.metadata = event.extendedProps.metadata
      }

      if (event.extendedProps.participants) {
        updatedEvent.participants = { link: event.extendedProps.participants }
      }
      let newImprovementIds = null
      if (event.extendedProps.improvements?.length) {
        let allImprovementIds = event.extendedProps.improvements.filter(e => e._id).map(e => e._id)
        const newImprovements = event.extendedProps.improvements.filter(e => !e._id)
        if (createImprovements) {
          try {
            newImprovementIds = await createImprovements(newImprovements, "event", null, false)
            if (newImprovementIds?.length) allImprovementIds = [...allImprovementIds, ...newImprovementIds]
          } catch (error) {
            console.log(error)
          }
        }
        if (allImprovementIds.length) updatedEvent.improvements = { link: allImprovementIds }
      } else {
        updatedEvent.improvements =  { link: [] }
      }

      updatedEvent.start = event.start instanceof Date ? event.start : new Date(event.start)
      updatedEvent.end = event.end instanceof Date ? event.end : new Date(event.end)

      if (!commitmentFunctionality || event.end <= event.start) {
        updatedEvent.end = new Date(updatedEvent.start)
        updatedEvent.end.setHours(updatedEvent.end.getHours() + 1 )
      }

      // Set calendar status
      updatedEvent.extendedProps = { calendar: event.extendedProps.calendar }
      if (event.extendedProps.calendar !== 'Realizadas') {
        updatedEvent.start.getTime() < now.getTime() ? updatedEvent.extendedProps.calendar = 'Vencidas' : null
        updatedEvent.start.getTime() >= now.getTime() ? updatedEvent.extendedProps.calendar = 'Pendientes' : null
        updatedEvent.start.getTime() >= firstDayOfNextMonth.getTime() ? updatedEvent.extendedProps.calendar = 'Futuras' : null
      }
      
      updatedEvent.modifiedAt = new Date()

      const query = {
        query: updateEvent,
        variables: {
          query: { _id: event.id },
          data: updatedEvent,
        },
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/graphql', query)
          .then(response => {
            response.data.data.updateOneEvent.newImprovementIds = newImprovementIds
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    removeEvent(ctx, { eventData, deletedJustification, deletedBy, improvementsToDelete }) {
      // Delete improvements associated with event
      if (eventData.extendedProps.improvements?.length) {
        eventData.extendedProps.improvements.forEach(i => {
          if (i._id) {
            axios
              .post('/graphql', {
                query: updateImprovement,
                variables: { query: { _id: i._id }, data: { deleted: true } }
              })
              .then(() => { })
              .catch((error) => {
                console.log(error)
              })
          }
        })
      }

      // Delete improvements already flagged for deletion
      if (improvementsToDelete?.length) {
        improvementsToDelete.forEach(i => {
          axios
            .post('/graphql', {
              query: updateImprovement,
              variables: { query: { _id: i._id }, data: { deleted: true } }
            })
            .then(() => {})
            .catch((error) => {
              console.log(error)
            })
        })
      }
      
      // Hard delete
      // const query = {
      //   query: removeEvent,
      //   variables: { query: { _id: id } },
      // }

      // Soft delete
      const query = {
        query: updateEvent,
        variables: {
          query: { _id: eventData.id },
          data: {
            deleted: true,
            deletedJustification,
            deletedBy: { link: deletedBy }
          }
        }
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/graphql', query)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
