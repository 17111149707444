export const queryEvents = `query events($query: EventQueryInput, $limit: Int, $sortBy: EventSortByInput){
  events(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    title
    start
    end
    allDay
    extendedProps {
      calendar
    },
    attendee {
      _id
      name
    }
    process {
      _id
      name
    }
    confirmation {
      _id
      pending
      supervisor {
        _id
        name
      }
    }
    modifiedAt
    isInstance
    isBehaviourEvaluation
    commitment {
      _id
      completed
    }
    metadata {
      name
      type
      answer
    }
    improvements {
      _id
      note
      description
      tags
      dueDate
      completed
      important
      deleted
      imageKey
      assignee {
        _id
        name
        email
      }
      metadata {
        name
        type
        answer
      }
      subscribers {
        _id
      }
      assistance
    }
    participants {
      _id
    }
    assistance
  }
}
`

export const queryEventsScore = `query events($query: EventQueryInput, $limit: Int){
  events(query: $query, limit: $limit) {
    extendedProps {
      calendar
    },
    organizer {
      name
      locations {
        _id
        location
      }
    }
    attendee {
      name 
      supervisors {
        _id
        name
        locations {
          location
        }
      }
      locations {
        _id
        location
      }
    }
    process {
      name
    }
    confirmation {
      _id
      score
      total
      duration
      date
      supervisor {
        _id
        name
        locations {
          location
        }
      }
      metadata {
        name
        answer
      }
      pending
      activities {
        behaviours {
          name
          type
          answer
        }
      }
    }
    _id
    start
    deleted
    deletedJustification
    deletedBy {
      name
    }
  }
}
`

export const addEvent = `mutation insertOneEvent($data: EventInsertInput!) {
  insertOneEvent(data: $data) {
    _id
    title
    start
    end
    allDay
    extendedProps {
      calendar
    },
    attendee {
      _id
      name
    }
    process {
      _id
      name
    }
    confirmation {
      _id
    }
  }
}
`

export const updateEvent = `mutation updateOneEvent($query: EventQueryInput, $data: EventUpdateInput!) {
  updateOneEvent(query: $query, set: $data) {
    _id
    title
    start
    end
    allDay
    extendedProps {
      calendar
    },
    attendee {
      _id
      name
    }
    process {
      _id
      name
    }
    confirmation {
      _id
    } 
  }
}
`

export const removeEvent = `mutation deleteOneEvent($query: EventQueryInput!) {
  deleteOneEvent(query: $query) {
    _id
  }
}
`

export const removeManyEvents = `mutation deleteManyEvents($query: EventQueryInput!) {
  deleteManyEvents(query: $query) {
    deletedCount
  }
}
`

export const addEventSync = `mutation insertOneEvent_sync($data: Event_syncInsertInput!) {
  insertOneEvent_sync(data: $data) {
    _id
  }
}
`

export const queryEventSyncs = `query event_syncs($query: Event_syncQueryInput, $limit: Int){
  event_syncs(query: $query, limit: $limit) {
    _id
    ms_event_id
    modifiedAt
  }
}
`

export const queryEvent = `query event($query: EventQueryInput){
  event(query: $query) {
    _id
    title
    start
    end
    allDay
    extendedProps {
      calendar
    },
    attendee {
      _id
      name
    }
    process {
      _id
      name
    }
    confirmation {
      _id
      pending
      supervisor {
        _id
        name
      }
    }
    modifiedAt
    isInstance
    isBehaviourEvaluation
    commitment {
      _id
      completed
    }
    metadata {
      name
      type
      answer
    }
    improvements {
      _id
      note
      description
      tags
      dueDate
      completed
      important
      deleted
      imageKey
      assignee {
        _id
        name
        email
      }
      metadata {
        name
        type
        answer
      }
      origin
      origin_id
      subscribers {
        _id
      }
      assistance
    }
    participants {
      _id
      name
    }
    assistance
  }
}
`

export const addManyEvents = `mutation insertManyEvents($data: [EventInsertInput!]!) {
  insertManyEvents(data: $data) {
    insertedIds
  }
}
`

export const updateManyEvents = `mutation updateManyEvents($query: EventQueryInput, $data: EventUpdateInput!) {
  updateManyEvents(query: $query, set: $data) {
    matchedCount
    modifiedCount
  }
}
`

export const queryEventId = `query event($query: EventQueryInput){
  event(query: $query) {
    _id
  }
}
`

export const queryParticipantsFromEvent = `query eventParticipants($query: EventQueryInput) {
  event(query: $query) {
    participants {
      _id
      name
      email
    }
  }
}
`